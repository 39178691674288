import React from 'react';

// ==============================|| DEFAULT PAGE ||============================== //

const Home = () => {
    console.log('Home');
    return (
        <>
            <div style={{ textAlign: 'center', margin: 20, fontWeight: 500, fontSize: 16 }}>Welcome to the PayzEasy portal!</div>
        </>
    );
};

export default Home;
